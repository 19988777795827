import React from "react";

const Shimmer = () => {
  return (
    <div className="shimmer-container">
      <div className="shimmer-left">
        <cashcard className="shimmer-shine"></cashcard>
        <div className="shimmer-bottom-fixed">
          <lines
            className="shimmer-shine shimmer-bottom-fixed"
            style={{ marginTop: "40px", height: "20px", width: "50%" }}
          ></lines>
        </div>
      </div>
      <div className="shimmer-right">
        <lines className="shimmer-shine"></lines>

        <div>
          <br></br>
        </div>
        <br></br>

        <cashcard className="shimmer-shine"></cashcard>
        <br></br>
        <br></br>
        <br></br>
        <div>
          <cashcard className="shimmer-shine"></cashcard>
          <br></br>
          <br></br>
          <br></br>

          <cashcard className="shimmer-shine"></cashcard>
          <br></br>
          <br></br>
        </div>
      </div>
      <lines className="shimmer-shine" style={{ height: "5px" }}></lines>

      <br></br>
      <lines className="shimmer-shine"></lines>

      <lines className="shimmer-shine"></lines>
      <lines className="shimmer-shine"></lines>

      <br></br>
      <cashcard className="shimmer-shine"></cashcard>
    </div>
  );
};

export default Shimmer;
