import { Switch, Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy } from "react";

// import Tutor from "@pages/Tutor";
// import Student from "@pages/Student";
import NavBar from "@molecules/NavBar";
import "./App.scss";
import Shimmer from "@molecules/Shimmer";

const Tutor = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("@pages/Tutor")), 1);
  });
});
const Student = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("@pages/Student")), 1);
  });
});

function App() {

  const DEFAULT_APP_ACCENT_COLOR = `009AE0`;

  const [color, setColor] = React.useState(DEFAULT_APP_ACCENT_COLOR);

  React.useEffect(() => {
    const appAccent = new URLSearchParams(window?.location?.search).get("color") || DEFAULT_APP_ACCENT_COLOR;
    setColor(appAccent);
  }, [])

  return (
    <div className="App" style={{
      "--appAccentColor": `#${color || DEFAULT_APP_ACCENT_COLOR}`,
    }}>
      <NavBar />
      <Switch>
        <Suspense fallback={<Shimmer />}>
          <Route exact path="/tutor" component={Tutor} />
          <Route exact path="/student" component={Student} />
          {/* <Redirect from="**" to="/student" /> */}
        </Suspense>
      </Switch>
    </div>
  );
}

export default App;
