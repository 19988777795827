/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import LeftArrow from '@images/arrow-left.svg';
import { useLocation } from 'react-router';

const NavBar = () => {
  let location = useLocation();
  useEffect(() => {
    window.onMobileUpdate = () => {
      handleBack();
    };
  }, []);
  const handleBack = () => {
    routeSwitchCases(0, 1);
  };
  const routeSwitchCases = (changeText, changeRoute) => {
    switch (location.pathname) {
      case '/tutor':
        if (changeRoute) {
          window?.mobile?.setTestState("QUIT");
          window?.webkit?.messageHandlers?.stateEvents?.postMessage("QUIT");
        }
        if (changeText) return "Installment Structure";
        break;
      case '/student':
        if (changeRoute) {
          window?.mobile?.setTestState(`QUIT`);
          window?.webkit?.messageHandlers?.stateEvents?.postMessage("QUIT");
        }
        if (changeText) return 'Pay In Installments';
        break;
      case '/':
        if (changeRoute) {
          window?.mobile?.setTestState(``);
          window?.webkit?.messageHandlers?.stateEvents?.postMessage('QUIT');
        }
        if (changeText) return "Installment Structure";
        break;
      default:
        return 'Error';
    }
  };
  const renderNavBarText = () => {
    return routeSwitchCases(1, 0);
  };
 
  return (
    <div className='Navbar'>
      <div className='Navbar__Left' onClick={handleBack}>
        <img src={LeftArrow} alt='ar' />
      </div>
      <div className='Navbar__Middle'>{renderNavBarText()}</div>
      <div className='Navbar__Right' >
        {/* <img src={Info} alt='in' /> */}
      </div>
    </div>
  );
};

export default React.memo(NavBar);
